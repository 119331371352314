import { OPTIMIZE_COOKIE_NAME } from 'constants/cookies';
import { getCookie } from 'cookies-next';
import { useEffect } from 'react';

export const useOptimizeVariant = (experimentID: string) => {
  const variant = getCookie(OPTIMIZE_COOKIE_NAME);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    window?.dataLayer?.push({
      event: 'experiment_impression',
      experiment_id: experimentID,
      variant_id: `${experimentID}.${variant}`,
      send_to: process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID,
    });
  }, []);
  return null;
};
