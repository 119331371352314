import { REFERRAL_COOKIE } from '@firi/shared/cookies';
import { getCookie, removeCookies, setCookies } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const CURRENT_AFFIALITE_BONUS = 'bonus2020';

export const useReferral = (params: { bonus?: boolean; code?: string }) => {
  const router = useRouter();
  const { bonus, code } = params;
  const thirtyDays = new Date();
  thirtyDays.setDate(thirtyDays.getDate() + 30);
  const cookieOptions = {
    domain: 'firi.com',
    path: '/',
    expires: thirtyDays,
    sameSite: 'lax' as any,
  };

  const setReferralCookie = (value: string) => {
    setCookies(REFERRAL_COOKIE, value, cookieOptions);
  };

  useEffect(() => {
    if (code) {
      setReferralCookie(code);
    } else if ('referral' in router.query) {
      removeCookies(REFERRAL_COOKIE, cookieOptions);
      setReferralCookie(router.query.referral as string);
    } else if (bonus) {
      setReferralCookie(CURRENT_AFFIALITE_BONUS);
    }
  }, [bonus, code]);

  return {
    referral: getCookie(REFERRAL_COOKIE),
    setCookie: setReferralCookie,
  };
};
