import { colors } from '@firi/shared/styles/colors.js';
import { Card, Icon } from '@firi/ui-web';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { format } from 'date-fns';
import { getLocalizedUrl } from 'helpers/url';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CryptocurrencyItemsData } from 'pages/api/cryptocurrencyItems';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { Button } from './Button';
import { Currency } from './Currency';
import { PercentChange } from './PercentChange';
import { Typography } from './Typography';

export const CustomTooltip = ({
  active,
  payload,
  fiat,
}: TooltipProps<any, any> & { fiat: 'NOK' | 'DKK' }) => {
  if (active && payload && payload.length > 0) {
    return (
      <Card border>
        <div className="flex flex-col">
          <Typography size="xs">{payload[0].payload.date}</Typography>
          <Typography size="sm">
            <Currency value={payload[0].value} /> {fiat}
          </Typography>
        </div>
      </Card>
    );
  }
  return <div />;
};

export const CryptoCurrencyCards = ({
  markets,
  horizontalScroll = true,
  border,
}: {
  markets: CryptocurrencyItemsData;
  horizontalScroll?: boolean;
  border?: boolean;
}) => {
  const router = useRouter();

  const filter = (market: any) => {
    if (!market.symbol) return false;
    if (!router.query.coin) return true;

    return market.slug?.current !== router.query.coin;
  };

  return (
    <div
      className={clsx('grid gap-2 md:grid-cols-9 lg:grid-cols-12', {
        'overflow-x-auto grid-flow-col md:grid-flow-row -mx-4 px-4': horizontalScroll,
      })}
    >
      {markets.filter(filter).map((market) => {
        const tokenColor = market.symbol
          ? colors.currency.light[market.baseCurrency?.symbol]
          : colors.blue[700];

        const dataPoints =
          market.priceHistory?.data.map((bar) => ({
            price: Number(bar?.price || 0),
            date: format(new Date(bar?.date || ''), 'MMM dd'),
          })) || [];
        return (
          <div
            key={market.symbol}
            className={clsx('col-span-1 md:col-span-3 md:w-full', {
              'w-[163px]': horizontalScroll,
            })}
          >
            <Link
              href={getLocalizedUrl(`/cryptocurrency/[coin]`, router.locale, {
                coin: market.slug?.current,
              })}
              locale={router.locale}
            >
              <a>
                <Card className="space-y-6 text-black-800" border={border}>
                  <div className="space-x-4 flex items-center justify-between md:justify-start">
                    <Icon size="xl" icon={market.baseCurrency?.symbol || 'generic-coin'} />
                    <Typography size="md" className="md:hidden">
                      {market.change && <PercentChange value={market.change} setPrefix />}
                    </Typography>
                    <Typography size="md" className="hidden md:inline-block">
                      {market.baseCurrency?.name}
                    </Typography>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <Typography size="md" className="block md:hidden">
                      {market.baseCurrency?.name}
                    </Typography>
                    <Typography size="md">
                      {market.lastPrice && <Currency value={market.lastPrice} />}
                    </Typography>
                    <Typography size="md" className="hidden md:inline-block">
                      {market.change && <PercentChange value={market.change} setPrefix />}
                    </Typography>
                  </div>
                  <div className="hidden h-[72px] w-full lg:block">
                    <ResponsiveContainer height={90} width="100%">
                      <LineChart data={dataPoints}>
                        <Line
                          dataKey="price"
                          stroke={tokenColor}
                          className=""
                          strokeWidth={1.5}
                          dot={false}
                        />
                        <Tooltip offset={10} content={(props) => <CustomTooltip {...props} />} />
                        <XAxis dataKey="date" hide />
                        <YAxis dataKey="price" type="number" hide domain={['dataMin', 'dataMax']} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="hidden lg:flex justify-center">
                    <Button skin="outlined" aria-label={t`Read more`}>
                      <Trans>Read more</Trans>
                    </Button>
                  </div>
                </Card>
              </a>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
