import { formatFiat, generateAffiliateLink, getPlatformLink } from '@firi/shared';
import HelloIcon from '@firi/shared/assets/illustrations/hello.svg';
import MagnifyingIcon from '@firi/shared/assets/illustrations/magnifier-glass.svg';
import { ReferralOwnerQuery as ReferralOwnerQueryType } from '@firi/shared/gql/gqlTypes';
import { ReferralOwnerQuery } from '@firi/shared/gql/queries/useReferralOwner';
import { Card, Chip, CopyButton, Divider, VippsButton } from '@firi/ui-web';
import { t, Trans } from '@lingui/macro';
import { Button } from 'components/Button';
import ContentWrapper from 'components/ContentWrapper';
import { CryptoCurrencyCards } from 'components/CryptoCurrencyCards';
import { Grid, GridItem } from 'components/Grid';
import { Meta } from 'components/Meta';
import { Typography } from 'components/Typography';
import { OPTIMIZE_COOKIE_NAME } from 'constants/cookies';
import { removeEmailExperimentID } from 'constants/optimizeABTests';
import { isBefore } from 'date-fns';
import { sendGTMEvent } from 'helpers/sendGTMEvent';
import { getLocalizedUrl } from 'helpers/url';
import { useOptimizeVariant } from 'hooks/useOptimizeVariant';
import { useReferral } from 'hooks/useReferral';
import { serverSideApolloClient } from 'lib/apollo/client';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useEffect } from 'react';
import QRCode from 'react-qr-code';

import { CryptocurrencyItemsData, getData } from '../api/cryptocurrencyItems';

const EXPIRED_CODE = 'expiredcode';

const AffiliatePage = ({
  data,
  abTestVariants,
  cryptocurrencies,
}: {
  data?: ReferralOwnerQueryType;
  abTestVariants: Record<string, string>;
  cryptocurrencies: CryptocurrencyItemsData;
}) => {
  const [appLinkUrl, setLink] = useState('');
  useOptimizeVariant(removeEmailExperimentID);
  const router = useRouter();

  const isDK = router.locale === 'dk';
  const referralCode = router.query?.referral || '';
  const referralAmount = formatFiat({ value: data?.referralOwner.welcomeBonus || '0' });
  const expiredReferral =
    data?.referralOwner && data?.referralOwner?.validTo
      ? isBefore(new Date(data.referralOwner.validTo), new Date())
      : false;

  useReferral({
    bonus: expiredReferral ? false : true,
    code: expiredReferral ? EXPIRED_CODE : undefined,
  });

  const variant = abTestVariants[OPTIMIZE_COOKIE_NAME];

  const howDoesItWorkItems = [
    {
      text: t`Download the app and register easily with Vipps.`,
    },
    {
      text: t`Add your friends referralcode when signing up`,
    },
    {
      text: t`We give you ${referralAmount} ${data?.referralOwner.welcomeBonusCurrency} to trade for when you answer "Yes, please!" to the welcome
      bonus. Good luck with your investments!`,
    },
  ];

  useEffect(() => {
    (async () => {
      const url = await generateAffiliateLink(String(referralCode), {
        socialMetaTagInfo: {
          socialTitle: t`Receive bonus when registering with Firi | Norwegian exchange for cryptocurrency trading`,
          socialDescription: t`Upon registration, you will receive a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.`,
          socialImageLink: isDK
            ? 'https://firi.com/images/banner-dk.jpg'
            : 'https://firi.com/images/banner.jpg',
        },
      });
      setLink(url);
    })();
  }, [referralCode]);

  return (
    <>
      <Meta
        noIndex
        title={t`Receive bonus when registering with Firi | Norwegian exchange for cryptocurrency trading`}
        description={t`Upon registration, you will receive a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.`}
      />
      <div className="bg-white">
        <ContentWrapper constrained className="text-center mt-6 lg:mt-12">
          <HelloIcon height="116" className="mx-auto mb-4 lg:mb-6" />
          <div className="mb-8 lg:mb-10">
            <Typography as="h1" size="xl" color="blue">
              {expiredReferral ? (
                <Trans>Welcome to Firi!</Trans>
              ) : (
                <>
                  {data?.referralOwner.name || <Trans>We</Trans>} <Trans>invite you to Firi!</Trans>
                </>
              )}
            </Typography>
            {data?.referralOwner.name === 'Xtrainvestor' && (
              <Typography as="p">
                <Trans>
                  Firi is a Norwegian cryptocurrency exchange, registered by Finanstilsynet
                </Trans>
              </Typography>
            )}
          </div>

          <Typography as="p" className="mb-8 max-w-screen-md mx-auto">
            <Trans>
              Download the Firi-app to get started and get {referralAmount}{' '}
              {data?.referralOwner.welcomeBonusCurrency} to buy your first cryptocurrency with Firi
              and start your investments.
            </Trans>
          </Typography>

          <div className="md:hidden mb-4">
            <a href={getLocalizedUrl(appLinkUrl, router.locale, router.query)}>
              <Button
                aria-label="Download our app"
                // eventAction="Footer"
                skin="primary"
                // id="sticky-footer-download-button"
                full
              >
                <Trans>Get your bonus in app</Trans>
              </Button>
            </a>
          </div>
          {!data?.referralOwner && referralCode && (
            <>
              <div className="md:hidden mb-4">
                <Typography as="p" size="sm">
                  <Trans>or copy code</Trans>
                </Typography>
              </div>
              <div className="md:hidden">
                <CopyButton value={String(referralCode)} icon="copy" skin="secondary" />
              </div>
            </>
          )}

          <Card border className="hidden md:block max-w-screen-md mx-auto">
            <div className="p-10">
              <div className="mb-7">
                <QRCode size={140} value={appLinkUrl} style={{ margin: 'auto' }} />
              </div>
              <Typography color="blue">
                <Trans>Scan QR-code to download our app and get your bonus</Trans>
              </Typography>
            </div>
          </Card>
          <div className="my-8">{!isDK && <Divider />}</div>

          {!isDK && (
            <div className="mb-10">
              <Typography as="p">
                <Trans>You can also continue on web with Vipps or email</Trans>
              </Typography>
            </div>
          )}

          {!isDK && (
            <div className="mb-6">
              <div className="flex justify-center mb-6 block">
                <VippsButton
                  vippsAction="register"
                  href={getPlatformLink({ link: 'vipps-signup' })}
                  aria-label={t`To sign up with Vipps`}
                  onClick={() => {
                    sendGTMEvent({
                      event_action: `Signup clicks`,
                      event_label: 'Signup with Vipps',
                      event_category: 'Identification',
                      signup_method: 'vipps',
                    });
                  }}
                />
              </div>

              <Typography>
                <Trans>or</Trans>{' '}
                <a
                  href={getPlatformLink({ link: 'email-signup' })}
                  aria-label={t`Register with email`}
                  onClick={() => {
                    sendGTMEvent({
                      event_action: `Signup clicks`,
                      event_label: 'Signup with email',
                      event_category: 'Identification',
                      signup_method: 'email',
                    });
                  }}
                >
                  <Trans>with email</Trans>
                </a>
              </Typography>
            </div>
          )}
        </ContentWrapper>
      </div>
      <div className="pt-12 pb-16">
        <ContentWrapper constrained>
          <div className="text-center mb-11">
            <Typography as="p" size="lg">
              <Trans>How does it work</Trans>?
            </Typography>
          </div>
          <ol className="space-y-7 lg:space-x-12 lg:space-y-0 lg:flex">
            {howDoesItWorkItems.map((item, index) => (
              <li>
                <div className="flex lg:flex-col">
                  <div className="mr-3 lg:mb-4">
                    <Chip variant="blue" rounded>
                      {index + 1}
                    </Chip>
                  </div>
                  <Typography>
                    <Trans id={item.text} />
                  </Typography>
                </div>
              </li>
            ))}
          </ol>
        </ContentWrapper>
      </div>
      <div className="bg-white">
        <ContentWrapper constrained className="text-center my-10 lg:my-20">
          <Grid>
            <GridItem className="!col-span-3 hidden lg:block">
              <MagnifyingIcon className="mx-auto mb-6" />
            </GridItem>
            <GridItem className="!col-span-7 text-center lg:text-left flex flex-col justify-center">
              <Typography as="p" size="lg" className="mb-11 lg:mb-7">
                <Trans>What is Firi?</Trans>
              </Typography>
              <MagnifyingIcon height="140" className="mx-auto mb-6 lg:hidden" />
              <Typography as="p" className="mb-16">
                <Trans>
                  Firi is Norway's largest service for buying and selling cryptocurrencies. With us
                  you will find, among other things, bitcoin, ethereum and cardano. We make the
                  cryptic easy by discovering new and better ways to save, invest and learn about
                  digital currency.
                </Trans>
              </Typography>
            </GridItem>
          </Grid>
          <Divider className="mb-11" />
          <div className="text-center mb-11">
            <Typography as="p" size="lg" className="lg:mb-4">
              <Trans>Cryptocurrency and prices</Trans>
            </Typography>
            <Typography size="md">
              <Trans>Price development last week</Trans>
            </Typography>
          </div>
          <CryptoCurrencyCards markets={cryptocurrencies} border />
        </ContentWrapper>
      </div>
      {/* {variant === '1' && <IntercomScript />} */}
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  query,
  req: { cookies },
  locale,
}) => {
  const cryptocurrencies = await getData({ country: locale === 'dk' ? 'DK' : 'NO' });
  const abTestVariants = { [OPTIMIZE_COOKIE_NAME]: cookies[OPTIMIZE_COOKIE_NAME] || null };

  if (!query.referral) {
    return {
      props: {
        abTestVariants,
        cryptocurrencies,
      },
    };
  }

  try {
    const { data }: { data: ReferralOwnerQueryType } = await serverSideApolloClient.query({
      query: ReferralOwnerQuery,
      variables: {
        code: query.referral,
      },
    });

    return {
      props: {
        data,
        abTestVariants,
        cryptocurrencies,
      },
    };
  } catch (e) {
    return {
      props: {
        abTestVariants,
        cryptocurrencies,
      },
    };
  }
};

export default AffiliatePage;
